$(document).foundation();

$(document).ready(function () {
    // utils
    initVerticalCenter();
    initAnimateInView();
    inlineSVGs([
	    '.header__call img',
        '.footer__social img',
        '.off-canvas__social img'
    ]);

    // global
	initOffCanvas();
    initHeader();
    initSliders();
    initVideoPopups();
	initPopup();
	initLanguageDropdown();

    // template specific
    initPricing();
    initAccordions();
    initForms();
    initActivities();
    initAttractionsMap();
    initDetailContent();
	initBlog();
	initPartners();
	initGallery();
});


function initLanguageDropdown() {
	$('.header__language select').selectric();
	$('.off-canvas__language select').selectric();
}


function initVerticalCenter() {
    $('.vertical-center').each(function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}



// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
    var selectorString = '';
    if (typeof inputValue === "object") {
        selectorString = inputValue.join(',');
    } else {
        selectorString = inputValue;
    }

    $(selectorString).each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        
        if (imgURL.indexOf('.svg') > -1) {
	        $.get(imgURL, function (data) {
	            // Get the SVG tag, ignore the rest
	            var $svg = $(data).find('svg');
	
	            // Add replaced image's ID to the new SVG
	            if (typeof imgID !== 'undefined') {
	                $svg = $svg.attr('id', imgID);
	            }
	            // Add replaced image's classes to the new SVG
	            if (typeof imgClass !== 'undefined') {
	                $svg = $svg.attr('class', imgClass + ' replaced-svg');
	            }
	
	            // Remove any invalid XML tags as per http://validator.w3.org
	            $svg = $svg.removeAttr('xmlns:a');
	
	            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
	            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
	                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
	            }
	
	            // Replace image with new SVG
	            $img.replaceWith($svg);
	        }, 'xml');
        }
    });
}



function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}



/**
 * Scales any element to simulate the CSS background-size: cover property.
 * @param {DOMelement} element The element to scale
 * @param {bool} [scaleToParent] Scale the element to fill the parent instead
 *                               of the whole browser window.
 */
function coverViewport(element, scaleToParent) {
    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = $(element).parent().innerWidth();
        viewportHeight = $(element).parent().innerHeight();
    } else {
        viewportWidth = $(window).innerWidth();
        viewportHeight = $(window).innerHeight();
    }

    var $element = $(element);

    var elementWidth = $element.attr('width');
    var elementHeight = $element.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $element.css('height', viewportHeight);
        $element.css('width', newWidth);
        $element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $element.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $element.css('width', viewportWidth);
        $element.css('height', newHeight);
        $element.css('left', '0');
        $element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $element.css('height', viewportHeight);
        $element.css('width', viewportWidth);
        $element.css('left', '0');
        $element.css('top', '0');
    }
}



function initAnimateInView() {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		//console.log($(this));
		if ( isElementInViewport($(this)) ) {
			$(this).addClass( $(this).data('animate-in-view') );
		}
	});
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport (el) {
    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();
    
    return (
	    rect.top <=  $(window).scrollTop() + window.innerHeight
    );
}



function initPricing() {
	if ( $('.pricing').length === 0 )
		return;
	
	showSelectedSeason();
	$('.pricing__selector select').on('change', showSelectedSeason);
	
	$('.pricing__selector select').selectric();
}

function showSelectedSeason() {
	var selectedSeason = $('.pricing__selector select').val();
	$('#season-tabs').foundation( 'selectTab', $('#' + selectedSeason) );
}



function initOffCanvas() {	
	// Generate the "Overview" links 
	$('.off-canvas__nav a').each(function () {
		if ( $(this).next().is('ul') ) {
			$(this).next().prepend('<li><a href="' + $(this).attr('href') + '">' + $(this).text() + ' Overview</a></li>');
		}
	});
	
	// Open the sub nav for sections which have sub pages
	$('.off-canvas__nav a').on('click', function (e) {
		if ( $(this).next().is('ul') ) {
			e.preventDefault();

			if ( $(this).parent().hasClass('open') ) {
				$(this).parent().removeClass('open');
				$(this).next('ul').slideUp();
			} else {
				$('.off-canvas__nav li.open ul').slideUp();
				$('.off-canvas__nav li.open').removeClass('open');
				$(this).parent().addClass('open');
				$(this).next('ul').slideDown();
			}
		}
	});
}



function initHeader() {
	
	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
	      deferTimer;
	  return function () {
	    var context = scope || this;
	
	    var now = +new Date,
	        args = arguments;
	    if (last && now < last + threshhold) {
	      // hold on to it
	      clearTimeout(deferTimer);
	      deferTimer = setTimeout(function () {
	        last = now;
	        fn.apply(context, args);
	      }, threshhold);
	    } else {
	      last = now;
	      fn.apply(context, args);
	    }
	  };
	}

	// Detect if the header is over and image slider, if yes then give it a transparent background
	setHeaderTransparency();
	$(window).on('scroll', setHeaderTransparency);
}



function setHeaderTransparency() {
	// Always solid header when there's no image slider
	if ( $('.slider, .blog-intro').length === 0 ) {
		$('.header').removeClass('header--transparent');
		return;
	}
	
	
	if (window.pageYOffset < 150) {
		$('.header').addClass('header--transparent');
	} else {
		$('.header').removeClass('header--transparent');
	}
	
	if ($('body').hasClass('off-canvas-open')) {
		$('.header').addClass('header--transparent');
	}
}




function initSliders() {
	$('.slider__inner').reformSlider({
		speed: 4000,
		animationSpeed: 1000,
		arrows: false,
		pagination: $('.slider').hasClass('slider--has-dots'),
		paginationArrows: false
	});
	
	if ( $('.slider__slide iframe').length > 0 ) {
		coverViewport('.slider__slide iframe');
		$(window).on('resize', function () {
			coverViewport('.slider__slide iframe');
		});
	}
	
	
	
	
	$('.slider__scroll button').on('click', function () {
		$('html, body').animate({
			scrollTop: $('.slider, .blog-intro').outerHeight() - $('.header').outerHeight()
		}, 1000);
		
		return false;
	});
	
	// If there's no slider, make sure that the content starts below the header
	if ($('.slider, .blog-intro').length === 0) {
		pushContentBelowHeader();
		$(window).on('resize', pushContentBelowHeader);
	}
	
	
	
	
	// Home intro carousel and custom controls
	var slideCount = $('.home-intro__carousel-item').length;
	$('.home-intro__carousel').after('<div class="home-intro__carousel-nav"><button class="prev">Previous</button> <span class="home-intro__carousel-nav-current">1</span><span>/' + slideCount + '</span> <button class="next">Next</button>');
	
	$('.home-intro__carousel').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		$('.home-intro__carousel-nav-current').text(parseInt(nextSlide) + 1);
	});
	
	$('.home-intro__carousel-nav .prev').on('click', function () {
		$('.home-intro__carousel').slick('slickPrev');
	});
	
	$('.home-intro__carousel-nav .next').on('click', function () {
		$('.home-intro__carousel').slick('slickNext');
	});
	
	$('.home-intro__carousel').slick({
		arrows: false,
		dots: false
	});
	
	
	
	
	$('.awards__carousel').slick({
		slidesToShow: 3
	});
	
	
	
	
	
	
	// Seasons homepage carousel, which changes the background image depending on the selected slide
	$('.seasons').prepend('<div class="seasons__backgrounds"></div>');
	
	var i = 0;
	$('.seasons__season').each(function () {
		var sliderClass = '';
		if (i == 0) {
			sliderClass = 'current';
		}
		
		$('.seasons__backgrounds').append('<div class="seasons__backgrounds-image ' + sliderClass + '" style="background-image: url(' + $(this).data('season-image') + ')"></div>"');
		
		i++;
	});
	
	$('.seasons__carousel').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		$('.seasons__backgrounds-image.current').removeClass('current');
		$('.seasons__backgrounds-image').eq(nextSlide).addClass('current');
	});
	
	$('.seasons__carousel').slick({
		fade: true,
		arrows: false,
		dots: true
	});
	
	
	
	
	
	// Landing page item sliders, with pagination dots which appear in a different location on medium up vs small
	$('.landing__slider-inner').each(function () {
		$(this).slick({
			speed: 1000,
			fade: true,
			dots: true,
			arrows: false,
			appendDots: $(this).parents('.landing__item').find('.landing__slider-dots')
		});
	});
	
	
	
	$('.detail__slider').each(function () {
		$(this).slick({
			speed: 1000,
			fade: true,
			dots: true,
			arrows: false/*,
			appendDots: $(this).parents('.landing__item').find('.landing__slider-dots')*/
		});
	});
}


function pushContentBelowHeader() {
	$('main').css('padding-top', $('.header').outerHeight() + 50);
}





function initVideoPopups() {
	var $modal;
	var modal = '<div id="video-modal" class="gallery-modal gallery-modal--video full reveal" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__iframe"><iframe frameborder="0" src="{{iframeSrc}}"></iframe></div></div>';

	// Detect video links and embed them in a popup
	$('a[href*="youtu"], a[href*="vimeo.com"]').not('[href*="youtube.com/channel"]').addClass('is-video').on('click', function () {
		$modal = $( modal.replace('{{iframeSrc}}', generateVideoEmbedUrl($(this).attr('href'))) );

		$('body').append($modal);

		$('#video-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
	        $modal.remove();
        });

		return false;
	});
}

function generateVideoEmbedUrl(videoUrl) {
	if (videoUrl.indexOf('youtube.com/embed') > -1) {
		// already a YouTube embed code, return same link
		return videoUrl;
	} else if (videoUrl.indexOf('youtu') > -1) {
		// must be either a full or shortened YouTube url so parse and create embed link
	    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
	    var match = videoUrl.match(regExp);

	    if (match && match[2].length == 11) {
	        return 'https://www.youtube.com/embed/' + match[2] + '?rel=0&autoplay=1&modestbranding=1';
	    } else {
	        return videoUrl;
	    }
	} else if (videoUrl.indexOf('player.vimeo.com') > -1) {
		// already a Vimeo embed code, return same link
		return videoUrl;
	} else if (videoUrl.indexOf('https://vimeo.com/') > -1) {
		// normal Vimeo link, so generate and embed code
		return 'https://player.vimeo.com/video/' + videoUrl.split('/')[3] + '?autoplay=true&byline=false&portrait=false&title=false';
	}

	// if all else fails, return the original link
	return embedUrl;
}




function initAccordions() {
	$('.accordion__inner').accordiom({
		showFirstItem: false
	});

	$('.header__language').accordiom({
		showFirstItem: false
	});

	$('.off-canvas__language-wrap').accordiom({
		showFirstItem: false
	});
}





function initForms() {
	$('.validate-this').validate();
	
	$('.form select').selectric();
}



function initActivities() {
	if ($('.activities').length === 0)
		return;
	
	findActivityContentHeight();
	$(window).on('resize', findActivityContentHeight);
	
	
	if ( window.location.href.indexOf('category=') > -1 ) {
		$('html, body').animate({
			scrollTop: $('.activities').offset().top - $('.header').outerHeight() - 20
        }, 0);
	}

	
	$('.activities__thumb').on('click', function () {
		var $item = $(this).parent().parent();
		
		if ( $item.hasClass('open') ) {
			
			// Close this item up
			$item.removeClass('open');
			$('.activities__item, .activities__grid').attr('style', ''); // move all items back up
			$item.find('.activities__content').slideUp();
			
		} else {
			
			// Close any other open ones first
			$('.activities__item.open .activities__content').slideUp();
			$('.activities__item.open').removeClass('open');
			$('.activities__item, .activities__grid').attr('style', ''); // move all items back up
			
			// Now open this one
			$item.addClass('open');
			$item.find('.activities__content').slideDown();
			
			var contentHeight = $item.find('.activities__content-inner').data('height');
			// Push down the bottom of the grid so we don't overlap the footer
			$('.activities__grid').css('padding-bottom', contentHeight + 'px');
			
			// Only push down items in the same column (i.e. left offset) as the item which is opened
			$item.nextAll('.activities__item').each(function () {
				if ( $(this).offset().left === $item.offset().left ) {
					$(this).css('transform', 'translateY(' + contentHeight + 'px)');
				}				
			});
			
		}
	});
}


// Find out how tall the content is to know how far to push the items below when it opens.
// Seriously. Fuck this layout of a template.
function findActivityContentHeight() {
	$('.activities__content').show();
	
	$('.activities__content-inner').each(function () {
		$(this).css({
		    position:   'absolute',
		    visibility: 'hidden',
		    display:    'block'
		});
		
		$(this).data('height', $(this).outerHeight());
		
		$(this).attr('style', '');
	});
	
	$('.activities__content').hide();
}



var customMapStyle=[{featureType:"all",elementType:"labels.text.fill",stylers:[{saturation:36},{color:"#ffffff"},{lightness:40},{visibility:"off"},{weight:"1.00"}]},{featureType:"all",elementType:"labels.text.stroke",stylers:[{visibility:"on"},{color:"#ffffff"},{lightness:16}]},{featureType:"all",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"administrative",elementType:"geometry.fill",stylers:[{lightness:20}]},{featureType:"administrative",elementType:"geometry.stroke",stylers:[{color:"#fefefe"},{lightness:17},{weight:1.2}]},{featureType:"landscape",elementType:"geometry",stylers:[{color:"#a6abb0"},{lightness:20},{visibility:"on"},{weight:"1.00"}]},{featureType:"poi",elementType:"geometry",stylers:[{color:"#9d831a"},{lightness:21},{visibility:"on"}]},{featureType:"poi.park",elementType:"geometry",stylers:[{color:"#1b0000"},{lightness:21},{visibility:"off"}]},{featureType:"road",elementType:"all",stylers:[{color:"#8f969c"}]},{featureType:"road.highway",elementType:"geometry.fill",stylers:[{lightness:17},{visibility:"on"}]},{featureType:"road.highway",elementType:"geometry.stroke",stylers:[{color:"#ffffff"},{lightness:29},{weight:.2},{visibility:"on"}]},{featureType:"road.arterial",elementType:"geometry",stylers:[{color:"#b8b8b8"},{lightness:18}]},{featureType:"road.local",elementType:"geometry",stylers:[{color:"#bebcbc"},{lightness:16},{visibility:"simplified"}]},{featureType:"transit",elementType:"geometry",stylers:[{color:"#f2f2f2"},{lightness:19}]},{featureType:"water",elementType:"geometry",stylers:[{color:"#6a737b"},{lightness:17}]}];

function initAttractionsMap() {
	$('[data-attractions-map]').each(function () {
		var map, centerLat, centerLng, zoom, marker, markerLat, markerLng;
		
		if (typeof $(this).data('map-center-coords') != 'undefined') {
			centerLat = parseFloat( $(this).data('map-center-coords').split(',')[0] );
			centerLng = parseFloat( $(this).data('map-center-coords').split(',')[1] );
		} else {
			centerLat = 0;
			centerLng = 0;
		}
		
		if ( $(window).innerWidth() <= 640 ) {
			zoom = $(this).data('map-zoom-small') || 8;
		} else {
			zoom = $(this).data('map-zoom') || 8;
		}
		
		if (typeof customMapStyle !== 'undefined') {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: customMapStyle
			});
		} else {
			map = new google.maps.Map($(this)[0], {
				center: {lat: centerLat, lng: centerLng},
				zoom: zoom,
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false
			});
		}
		
		
		$(this).siblings('[data-map-marker]').each(function () {
			if (typeof $(this).data('map-marker-coords') != 'undefined') {
				markerLat = parseFloat( $(this).data('map-marker-coords').split(',')[0] );
				markerLng = parseFloat( $(this).data('map-marker-coords').split(',')[1] );
			} else {
				markerLat = 0;
				markerLng = 0;
			}
			
			marker = new google.maps.Marker({
				position: {lat: markerLat, lng: markerLng},
				map: map
			});
			
			if (typeof $(this).data('map-marker-url') != 'undefined') {
				marker.setIcon($(this).data('map-marker-url'));
			}
		});
	});
}



// Calculate the height of the content panel to match the images
function initDetailContent() {
	if ( $('.detail').length > 0 ) {
		Foundation.onImagesLoaded($('.detail__slider-images-right img'), setDetailContentHeight);
		$(window).on('resize', setDetailContentHeight);
	}
}

function setDetailContentHeight() {
	if (window.innerWidth > 1024) {
		$('.detail').each(function () {
			var contentHeight = $(this).find('.detail__content-inner').outerHeight();
			var imageHeight   = $(this).find('.detail__slider-images-inner').outerHeight();
			
			if (imageHeight > contentHeight) {
				$(this).find('.detail__content-inner').css('height', imageHeight + 'px');
			}
		});
	} else {
		$('.detail__content-inner').css('height', 'auto');
	}
}

function initPopup() {
    if ($('#popup').length > 0) {
        var popupDelay = $('#popup').data('popup-delay') || 0;
        var popupKeepHiddenFor = $('#popup').data('popup-keep-hidden-for-n-days') || 0;

        if (!Cookies.get('hasSeenPopup')) {
            setTimeout(function() {
                $('#popup').foundation('open');

                if (popupKeepHiddenFor > 0) {
                    Cookies.set('hasSeenPopup', 'true', { expires: popupKeepHiddenFor });
                }
            }, popupDelay);
        }
    }
}




function initBlog() {
	/*$('.blog-landing__filters').accordiom({
		showFirstItem: false
	});*/
	
	$('.blog-landing__filters select').selectric({
		onChange: function(element) {
			window.location = element.value;
	    },
	});
}

function initPartners() {
	if ($('.partners').length === 0)
		return;
	
	// findPartnerContentHeight();
	// $(window).on('resize', findPartnerContentHeight);
	
	
	if ( window.location.href.indexOf('category=') > -1 ) {
		$('html, body').animate({
			scrollTop: $('.partners').offset().top - $('.header').outerHeight() - 20
        }, 0);
	}

	
	// $('.partners__thumb').on('click', function () {
	// 	var $item = $(this).parent().parent();
		
	// 	if ( $item.hasClass('open') ) {
			
	// 		// Close this item up
	// 		$item.removeClass('open');
	// 		$('.partners__item, .partners__grid').attr('style', ''); // move all items back up
	// 		$item.find('.partners__content').slideUp();
			
	// 	} else {
			
	// 		// Close any other open ones first
	// 		$('.partners__item.open .partners__content').slideUp();
	// 		$('.partners__item.open').removeClass('open');
	// 		$('.partners__item, .partners__grid').attr('style', ''); // move all items back up
			
	// 		// Now open this one
	// 		$item.addClass('open');
	// 		$item.find('.partners__content').slideDown();
			
	// 		var contentHeight = $item.find('.partners__content-inner').data('height');
	// 		// Push down the bottom of the grid so we don't overlap the footer
	// 		$('.partners__grid').css('padding-bottom', contentHeight + 'px');
			
	// 		// Only push down items in the same column (i.e. left offset) as the item which is opened
	// 		$item.nextAll('.partners__item').each(function () {
	// 			if ( $(this).offset().left === $item.offset().left ) {
	// 				$(this).css('transform', 'translateY(' + contentHeight + 'px)');
	// 			}				
	// 		});
			
	// 	}
	// });
}

function findPartnerContentHeight() {
	$('.partners__content').show();
	
	$('.partners__content-inner').each(function () {
		$(this).css({
		    position:   'absolute',
		    visibility: 'hidden',
		    display:    'block'
		});
		
		$(this).data('height', $(this).outerHeight());
		
		$(this).attr('style', '');
	});
	
	$('.partners__content').hide();
}

function initGallery() {
	$('.gallery__carousel').slick({
		centerMode: true,
		centerPadding: '0px',
		arrows: false,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 640,
				settings: {
					centerMode: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				}
			}
		]
	});
	
	// click on the next/prev slide to scroll through them
	$('.gallery__carousel-item').on('click', function () {
		if ( $(this).parents('.slick-slide').next().hasClass('slick-current') ) {
			$(this).parents('.gallery__carousel').slick('slickPrev');
		} else if ( $(this).parents('.slick-slide').prev().hasClass('slick-current') ) {
			$(this).parents('.gallery__carousel').slick('slickNext');
		}
	});

	// Popups on the gallery template
	$('button.gallery__magnify, .gallery__carousel-item-title button').on('click', function () {
		// Dynamically load the gallery slider into a reveal popup

		var galleryImageIndex = $(this).data('gallery-index') || 0;

		var $modal = $('<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__slider"></div></div>');

        $('body').append($modal);

        $modal.find('.gallery-modal__slider').load($(this).parents('.gallery__carousel').data('gallery-slider'), function () {
           $modal.removeClass('loading').find('.gallery-modal__slider').slick({
              arrows: true,
              fade: true
          }).slick('slickGoTo', galleryImageIndex, false);
       });

        $('#gallery-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
           $modal.remove();
       });
    });
	
	
	// Popups on any CTA link
	$('a[href="_ajax-gallery.html"], a[href*="gallery-popup"], a[data-gallery-popup]').on('click', function () {
		// Dynamically load the gallery slider into a reveal popup

		var $modal = $('<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__slider"></div></div>');

		$('body').append($modal);

		$modal.find('.gallery-modal__slider').load($(this).attr('href'), function () {
		   $modal.removeClass('loading').find('.gallery-modal__slider').slick({
			  arrows: true,
			  fade: true
		  });
	   });

		$('#gallery-modal').foundation();
		$modal.foundation('open');

		$modal.on('closed.zf.reveal', function () {
		   $modal.remove();
	   });
	   
	   return false;
	});
}